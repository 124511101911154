import React from "react"
import Layout from '../layouts/layout'
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import Style from './blog.module.scss'
import Title from "../components/Title";

export const query = graphql`
    query ($language: String) {
        allMarkdownRemark(limit: 20, sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {language: {eq: $language}, type: {eq: "work"}}}) {
            edges {
                node {
                frontmatter {
                    language
                    title
                    date(formatString: "DD MMMM YYYY", locale: $language)
                    slug
                    description
                    thumbnail {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                transformOptions: {
                                    fit: COVER
                                }
                                height: 250
                            ) 
                        }
                    }
                }
                timeToRead
                html
                }
            }
        },
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
              node {
                ns
                data
                language
              }
            }
          
        }
    }
    `

const Work = (props) => {
    const {t} = useTranslation();
    const posts = [];
    props.data.allMarkdownRemark.edges.forEach(edge => posts.push(

            <Link to={'/works/'+edge.node.frontmatter.slug} key={edge.node.frontmatter.title} className={Style.card}>
                <GatsbyImage alt={edge.node.frontmatter.title} image={getImage(edge.node.frontmatter.thumbnail)} style={{maxWidth: '100%'}} imgStyle={{maxWidth: '100%',objectFit: 'contain'}}/>
                <span className={Style.card_title}>{edge.node.frontmatter.title}</span>
                <span className={Style.card_description}>{edge.node.frontmatter.description}</span>

                <small className={Style.card_date}>{edge.node.frontmatter.date}</small>
            </Link>

    ))

    return (
        <div>
            <Layout>
                <Helmet>
                    <title>{t("works_title")}</title>
                </Helmet>
                <Title translation="works_title" />
                <div className={Style.wrapper}>
                    <div className={Style.list}>    
                        {posts}
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Work
